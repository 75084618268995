import { actions } from "astro:actions";
import { EyeIcon, EyeOffIcon, Loader2, UserRound } from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { cn } from "@/lib/utils";
import { PUBLIC_GOOGLE_ENABLED } from "astro:env/client";
import { type PropsWithChildren, useState } from "react";
import { FaGoogle } from "react-icons/fa";
import { toast } from "sonner";
import AlertError from "../alert-error";
import LoginviaOtp from "./login-via-otp";
import ForgotPassword from "./forgot-password";

type Props = PropsWithChildren & {
  redirectTo?: string;
  hasError?: boolean;
  message?: string;
  siteName: string;
  buttonClasses?: string;
};

const Login = ({
  redirectTo,
  hasError,
  message,
  siteName,
  buttonClasses,
  children,
}: Props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loginViaOTP, setLoginViaOTP] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [forgotPassword, setForgetPassword] = useState(false);

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);
    setError(null);

    const { data, error } = await actions.auth.loginAction({
      username,
      password,
    });

    if (error) {
      setIsLoading(false);
      setError(error.message);
      return;
    }

    toast.success("Logged In Successfully");

    if (redirectTo) {
      document.location = redirectTo;
      return;
    }

    const { role } = data.user;
    let url = "/";

    switch (role) {
      case "ADMIN":
      case "EMPLOYEE":
      case "SUPERADMIN":
        url = "/admin";
        break;
      case "USER":
        url = "/dashboard";
        break;
      case "SHOP_MANAGER":
        url = "/shop-dashboard";
        break;
      case "DELIVERY_GUY":
        url = "/delivery-guy-dashboard";
        break;
      case "INFLUENCER":
        url = "/influencer-dashboard";
        break;
      default:
        break;
    }

    document.location = url;
  };

  if (loginViaOTP)
    return (
      <LoginviaOtp
        redirectTo={redirectTo}
        hasError={hasError}
        message={message}
        siteName={siteName}
        onLoginViaPassword={() => setLoginViaOTP(false)}>
        {children}
      </LoginviaOtp>
    );

  return (
    <section className="py-8">
      <div className="">
        <div className="flex flex-col gap-4">
          {/* <img
            src="logo"
            alt="logo"
            className="h-8"
          /> */}
          <Card className="mx-auto w-full max-w-md">
            <CardHeader className="items-center">
              <UserRound className="size-10 rounded-full bg-accent p-2.5 text-muted-foreground" />
              <CardTitle className="text-xl">Log in</CardTitle>
              <CardDescription>To your {siteName} account</CardDescription>
            </CardHeader>
            <CardContent>
              {PUBLIC_GOOGLE_ENABLED && (
                <>
                  <div className="flex w-full flex-col gap-3">
                    <a href="/oauth/google">
                      <Button
                        variant="outline"
                        className="w-full bg-rose-500 hover:bg-rose-600 text-white hover:text-white border-transparent"
                        type="button">
                        <FaGoogle className="mr-2 size-4" />
                        Sign in with Google
                      </Button>
                    </a>

                    <div className="flex items-center gap-4">
                      <span className="h-px w-full bg-input"></span>
                      <span className="text-xs text-muted-foreground">OR</span>
                      <span className="h-px w-full bg-input"></span>
                    </div>
                  </div>
                </>
              )}

              <div className="grid gap-4">
                {hasError && message && <AlertError message={message} />}

                <div className="hidden items-center gap-4">
                  <span className="h-px w-full bg-input"></span>
                  <span className="text-xs text-muted-foreground">OR</span>
                  <span className="h-px w-full bg-input"></span>
                </div>

                <form
                  onSubmit={handleLogin}
                  method="POST"
                  className="grid gap-4">
                  <div className="grid gap-2">
                    <Label htmlFor="email">Email/Username</Label>
                    <Input
                      id="email"
                      name="email"
                      type="email"
                      placeholder="email@example.com"
                      required
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                  <div className="grid gap-2">
                    <div className="flex justify-between">
                      <Label htmlFor="password">Password</Label>
                      <button
                        type="button"
                        className="text-sm underline"
                        onClick={() => setForgetPassword(true)}>
                        Forgot password
                      </button>
                      <Dialog open={forgotPassword} onOpenChange={setForgetPassword}>
                        <DialogContent className="sm:max-w-[425px]">
                          <ForgotPassword siteName={siteName}
                          onClose={() => setForgetPassword(false)}>
                            <div className="text-sm">
                              {/* <p>Don&apos;t have an account yet?</p> */}
                            </div>
                          </ForgotPassword>
                        </DialogContent>
                      </Dialog>
                    </div>
                    <div className="relative">
                      <Input
                        id="password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter your password"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />

                      <div className="absolute top-0 right-2 bottom-0 grid items-center">
                        <button
                          type="button"
                          onClick={() => setShowPassword(!showPassword)}>
                          {!showPassword ? (
                            <EyeIcon className="h-4 w-4 text-muted-foreground" />
                          ) : (
                            <EyeOffIcon className="h-4 w-4 text-muted-foreground" />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                  <Button
                    type="submit"
                    className={cn("w-full", buttonClasses)}
                    disabled={isLoading}>
                    Log in
                    {isLoading && <Loader2 className="ml-2 animate-spin" />}
                  </Button>

                  <div className="flex items-center gap-4">
                    <span className="h-px w-full bg-input"></span>
                    <span className="text-xs text-muted-foreground">OR</span>
                    <span className="h-px w-full bg-input"></span>
                  </div>

                  <Button
                    variant={"subtle"}
                    type="button"
                    className="w-full"
                    onClick={() => setLoginViaOTP(true)}>
                    Send Login Code
                  </Button>

                  {error && <AlertError message={error} />}
                </form>
              </div>
            </CardContent>
          </Card>

          {/* extra info */}
          {children}
        </div>
      </div>
    </section>
  );
};

export default Login;
