import { actions } from "astro:actions";
import { Loader2, UserRound } from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { cn } from "@/lib/utils";
import { type PropsWithChildren, useState } from "react";
import { FaGoogle } from "react-icons/fa";
import { toast } from "sonner";
import AlertError from "../alert-error";
import { PUBLIC_GOOGLE_ENABLED } from "astro:env/client";

type Props = PropsWithChildren & {
  redirectTo?: string;
  hasError?: boolean;
  message?: string;
  siteName: string;
  buttonClasses?: string;
  onLoginViaPassword?: () => void;
};

const LoginviaOtp = ({
  redirectTo,
  hasError,
  message,
  siteName,
  buttonClasses,
  onLoginViaPassword,
  children,
}: Props) => {
  const [email, setEmail] = useState<string | undefined>();
  const [otp, setOtp] = useState<string | undefined>();
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [otpWasSent, setOtpWasSent] = useState(false);
  const [otpRequestMessage, setOtpRequestMessage] = useState<
    string | undefined
  >();

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!email) {
      toast.error("Please provide your email");
      return;
    }

    setIsLoading(true);
    setError(null);

    const { data, error } = await actions.auth.requestLoginOTP({
      email,
      domain: window.location.hostname,
    });

    setIsLoading(false);

    if (error) {
      setError(error.message);
      return;
    }

    toast.success(data.message);
    setOtpRequestMessage(data.message);
    setOtpWasSent(true);
  };

  const handleValidateOTP = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!email) {
      toast.error("Please provide your email");
      return;
    }

    if (!otp) {
      toast.error("Please provide your OTP code");
      return;
    }

    setIsLoading(true);
    setError(null);

    const { data, error } = await actions.auth.verifyLoginOTP({
      email,
      otp,
    });

    if (error) {
      setIsLoading(false);
      setError(error.message);
      return;
    }

    toast.success(data.message);
    if (redirectTo) {
      document.location = redirectTo;
      return;
    }

    const { role } = data.user;
    let url = "/";

    switch (role) {
      case "ADMIN":
      case "EMPLOYEE":
      case "SUPERADMIN":
        url = "/admin";
        break;
      case "USER":
        url = "/dashboard";
        break;
      case "SHOP_MANAGER":
        url = "/shop-dashboard";
        break;
      case "DELIVERY_GUY":
        url = "/delivery-guy-dashboard";
        break;
      case "INFLUENCER":
        url = "/influencer-dashboard";
        break;
      default:
        break;
    }

    document.location = url;
  };

  const handleRequestNewCode = () => {
    setOtpWasSent(false);
    setOtpRequestMessage(undefined);
  };

  return (
    <section className="py-8">
      <div className="">
        <div className="flex flex-col gap-4">
          {/* <img
            src="logo"
            alt="logo"
            className="h-8"
          /> */}
          {!otpWasSent ? (
            <Card className="mx-auto w-full max-w-md">
              <CardHeader className="items-center">
                <UserRound className="size-10 rounded-full bg-accent p-2.5 text-muted-foreground" />
                <CardTitle className="text-xl">Log in</CardTitle>
                <CardDescription>To your {siteName} account</CardDescription>
              </CardHeader>
              <CardContent>
                <div className="grid gap-4">
                  {hasError && message && <AlertError message={message} />}

                  {PUBLIC_GOOGLE_ENABLED && (
                    <>
                      <a href="/oauth/google">
                        <Button
                          variant="outline"
                          className="w-full bg-rose-500 hover:bg-rose-600 text-white hover:text-white border-transparent"
                          type="button">
                          <FaGoogle className="mr-2 size-4" />
                          Sign in with Google
                        </Button>
                      </a>

                      <div className="flex items-center gap-4">
                        <span className="h-px w-full bg-input"></span>
                        <span className="text-xs text-muted-foreground">
                          OR
                        </span>
                        <span className="h-px w-full bg-input"></span>
                      </div>
                    </>
                  )}

                  <form
                    onSubmit={handleLogin}
                    method="POST"
                    className="grid gap-4">
                    <div className="grid gap-2">
                      <Label htmlFor="email">Email</Label>
                      <Input
                        id="email"
                        name="email"
                        type="email"
                        placeholder="email@example.com"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>

                    <Button
                      type="submit"
                      className={cn("w-full", buttonClasses)}
                      disabled={isLoading}>
                      Send Login Code
                      {isLoading && <Loader2 className="ml-2 animate-spin" />}
                    </Button>

                    {error && <AlertError message={error} />}
                  </form>
                </div>
              </CardContent>
              <CardFooter>
                <LoginViaPasswordButton
                  onLoginViaPassword={onLoginViaPassword}
                />
              </CardFooter>
            </Card>
          ) : (
            <div>
              <Card className="mx-auto w-full max-w-md">
                <CardHeader className="items-center">
                  <UserRound className="size-10 rounded-full bg-accent p-2.5 text-muted-foreground" />
                  <CardTitle className="text-xl">Enter Login Code</CardTitle>
                  <CardDescription>Sent to your email</CardDescription>
                </CardHeader>
                <CardContent>
                  <div className="grid gap-4">
                    {otpRequestMessage && (
                      <p className="text-sm text-center">{otpRequestMessage}</p>
                    )}
                    <Button
                      asChild
                      variant={"ghost"}
                      type="button"
                      className=" underline underline-offset-4">
                      <a href={`mailto:${email}`}>{email}</a>
                    </Button>
                    {hasError && message && <AlertError message={message} />}

                    <form
                      onSubmit={handleValidateOTP}
                      method="POST"
                      className="grid gap-4">
                      <div className="grid gap-2">
                        <Label htmlFor="otp">Login Code (OTP)</Label>
                        <Input
                          id="otp"
                          name="otp"
                          autoComplete="off"
                          type="text"
                          placeholder="1234"
                          required
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                        />
                      </div>

                      <Button
                        type="submit"
                        className={cn("w-full", buttonClasses)}
                        disabled={isLoading}>
                        Verify Login Code
                        {isLoading && <Loader2 className="ml-2 animate-spin" />}
                      </Button>

                      <Button
                        variant={"link"}
                        onClick={handleRequestNewCode}
                        type="button">
                        Request New Login Code
                      </Button>

                      {error && <AlertError message={error} />}
                    </form>
                  </div>
                </CardContent>
                <CardFooter>
                  <LoginViaPasswordButton
                    onLoginViaPassword={onLoginViaPassword}
                  />
                </CardFooter>
              </Card>
            </div>
          )}

          {/* extra info */}
          {children}
        </div>
      </div>
    </section>
  );
};

const LoginViaPasswordButton = ({
  onLoginViaPassword,
}: {
  onLoginViaPassword?: () => void;
}) => {
  return (
    <div className="flex w-full flex-col gap-3">
      <div className="flex items-center gap-4">
        <span className="h-px w-full bg-input"></span>
        <span className="text-xs text-muted-foreground">OR</span>
        <span className="h-px w-full bg-input"></span>
      </div>

      <Button
        variant={"subtle"}
        type="button"
        className="w-full"
        onClick={onLoginViaPassword}>
        Login via Password
      </Button>
    </div>
  );
};

export default LoginviaOtp;
